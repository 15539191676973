// ImageSlider.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import Slider from 'react-slick';

import WebContext from '../../../../application/contexts/WebContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './image-slider.scss';

const ImageSlider = ({ slides }) => {
  const webCtx = useContext(WebContext);

  const [sliderIndex, setSliderIndex] = useState(0);
  const [slideTimer] = useState(5000);
  const sliderRef = useRef(null);

  useEffect(() => {
    // Initialize Slick Slider
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(sliderIndex);
    }
  }, [sliderIndex]);

  const customDots = (i) => {
    return (
      <div className="position-relative">
        <div className="progress line-progress" style={{ backgroundColor: 'green' }}>
          <div className="progress-bar filled"></div>
        </div>
        <div
          className={`custom-dot-text ${i === sliderIndex ? 'active' : ''}`}
          onClick={() => setSliderIndex(i)}>
          {/* {slides[i].title} */}
          {slides[i][`title_${webCtx.appLang}`]}
        </div>
      </div>
    );
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Autoplay is managed manually
    autoplaySpeed: slideTimer,
    pauseOnHover: false,
    dots: true,
    customPaging: customDots
    // cssEase: "linear"
  };

  return (
    <div className="image-slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="slide-data" key={index}>
            <div className="slide-bg" style={{ backgroundImage: `url('${slide.image}')` }}>
              <div className="slide-content-container h-100">

                <div className="container-xxl h-100 px-4 px-md-2">
                  <div className="slide-content">
                    <div className="title h3">{slide[`title_${webCtx.appLang}`]}</div>
                    <div className="desc">
                      <div className="render-content" dangerouslySetInnerHTML={{ __html: slide[`content_${webCtx.appLang}`] }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;