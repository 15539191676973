import React, { useState, useContext } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import WebContext from '../../../application/contexts/WebContext';

import { ReactComponent as AssetSun } from './../../../assets/images/icons/theme-sun.svg';
import { ReactComponent as AssetMoon } from './../../../assets/images/icons/theme-moon.svg';
import { ReactComponent as AssetEnglish } from './../../../assets/images/icons/flag-uk.svg';
import { ReactComponent as AssetBulgarian } from './../../../assets/images/icons/flag-bg.svg';
import assetLogo from './../../../assets/images/logo-nodis.png';

import './navbar.scss';

const Navbar = () => {
    const webCtx = useContext(WebContext);
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const switchTheme = () => {
        const previousTheme = webCtx.appTheme;
        const theme = (webCtx.appTheme === 'light-theme') ? 'dark-theme' : 'light-theme';
        document.body.classList.replace(previousTheme, theme);
        webCtx.switchTheme(theme);
    };

    const switchLanguage = (e) => {
        const lang = (webCtx.appLang === 'en') ? 'bg' : 'en';
        i18n.changeLanguage(lang);
        webCtx.switchLang(lang);
    };

    const menuItems = [
        { title: `${t('menu_courses')}`, url: '/courses' },
        { title: `${t('menu_partners')}`, url: '/partners' },
        { title: `${t('menu_blogs_n_news')}`, url: '/blog-n-news' },
        { title: `${t('menu_contact_us')}`, url: '/contact-us' },
    ];

    // write ReactJs css dark & light theme using these colors '#d81f28' and '#203676'?

    return (
        <React.Fragment>
            <header id="header" >
                <div className="container-xxl d-flex align-items-center py-2">

                    <div className="logo me-5">
                        <NavLink className="menu-item fs-2 text-uppercase" to='/'>
                            <img src={assetLogo} alt="logo-nodis" />
                        </NavLink>
                    </div>


                    <nav className="nodis-menu d-none d-md-flex">
                        {
                            menuItems.map((item) => (
                                <NavLink className="menu-item" to={item.url} key={item.url}>
                                    <span>{item.title}</span>
                                </NavLink>
                            ))
                        }
                    </nav>

                    <div className="ms-auto d-none d-md-block">
                        <button className="btn nodis-btn outline small me-3" onClick={switchLanguage} data-toggle="tooltip" data-placement="bottom" title="Tooltip on right">
                            {(webCtx.appLang === 'en') ? <AssetBulgarian /> : <AssetEnglish />}
                        </button>

                        <button className="btn nodis-btn outline small" onClick={switchTheme}>
                            {(webCtx.appTheme === 'light-theme') ? <AssetMoon /> : <AssetSun />}
                        </button>
                    </div>

                </div>
            </header>

            <header className="mobile-menu s-header d-md-none">
                <nav className={`header-nav p-4 px-5 ${isMenuOpen ? 'menu-is-open' : ''}`}>
                    <div className="header-nav__close nav-close" title="close" onClick={() => setIsMenuOpen(false)}><span>Close</span></div>
                    <h3 className="navigate">{t('navigate_to')}</h3>
                    <div className="header-nav__content mt-5">
                        <ul className="header-nav__list">
                                    <li>
                                        <NavLink className="menu-item" to={'/'}>
                                            <span>{t('menu_home')}</span>
                                        </NavLink>
                                    </li>
                            {
                                menuItems.map((item) => (
                                    <li key={item.url}>
                                        <NavLink className="menu-item" to={item.url}>
                                            <span>{item.title}</span>
                                        </NavLink>
                                    </li>
                                ))
                            }
                            <hr />
                            {/* {webCtx.isLoggedIn ?
                                <React.Fragment>
                                    {webCtx.isAdmin() &&
                                        <li><Link to="/admin">{t('admin_panel')}</Link></li>
                                    }
                                    {webCtx.isManager() &&
                                        <li><Link to="/representative" className="item">{t('dashboard')}</Link></li>
                                    }
                                    <li><span data-bs-toggle="modal" data-bs-target="#logoutModal">{t('logout')}</span></li>
                                </React.Fragment>
                                :
                                <li><Link to="/login">{t('login')}</Link></li>
                            }
                            <hr /> */}

                            <div className="d-flex align-items-center justify-content-between">
                                <div className="action-item d-md-none">
                                    <button className="btn nodis-btn outline small" onClick={switchTheme}>
                                        {(webCtx.appTheme === 'light-theme') ? <AssetMoon /> : <AssetSun />}
                                    </button>
                                </div>
                                <div className="action-item d-md-none">
                                    <button className="btn nodis-btn outline small" onClick={switchLanguage} data-toggle="tooltip" data-placement="bottom" title="Tooltip on right">
                                        {(webCtx.appLang === 'en') ? <AssetBulgarian /> : <AssetEnglish />}
                                    </button>
                                </div>
                            </div>
                        </ul>
                    </div>
                </nav>

                <button className="header-menu-toggle" onClick={() => setIsMenuOpen(true)}>
                    <span className="header-menu-icon"></span>
                </button>

            </header>
        </React.Fragment>

    );
};

export default Navbar;