import axios from 'axios';
import Global from '../../../infrastructure/Common/Global';

class ClientApis {

    feSettings() {
        // const qParam = account ? '?account=1' : '';
        return axios.get(Global.feSettings);
    }

    courses(qParam) {
        return axios.get(Global.courses + `${qParam ? ('?' + qParam) : ''}`);
    }

    getCourse(courseId) {
        return axios.get(Global.course + '/' + courseId);
    }

    cmsPost(data) {
        return axios.post(Global.cmsPost, data);
    }

    cmsPostBulk(data) {
        return axios.post(Global.cmsPostBulk, data);
    }

    partners(qParam) {
        return axios.get(Global.partners + `${qParam ? ('?' + qParam) : ''}`);
    }

    partner(partnerId) {
        return axios.get(Global.partners + `${'/' + partnerId}`);
    }

    banners() {
        return axios.get(Global.banners);
    }

    blog(qParam) {
        return axios.get(Global.blog + `${qParam ? ('?' + qParam) : ''}`);
    }

    blogPost(data) {
        return axios.post(Global.postDetail, data);
    }

    contactUs(data){
        return axios.post(Global.contactUs, data);

    }

}

export default new ClientApis();
