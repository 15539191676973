import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import WebContext from '../../../../application/contexts/WebContext';
import Global from '../../../../infrastructure/Common/Global';

import CourseCard from '../../Shared/CourseCard';
import ClientApis from '../../APIs/ClientApis';

const LandingCourses = () => {
    // const baseURL = Global.baseUrl + "/";
    const { t } = useTranslation();
    const webCtx = useContext(WebContext);

    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState([]);

    const baseURL = Global.baseUrl + "/";

    useEffect(() => {
        getCourses();
    }, []);

    const getCourses = () => {
        ClientApis.courses('home=1')
            .then(res => {
                setCourses(res.data);
                // setTimeout(() => {
                setIsLoading(false);
                // }, 2000);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    };

    return (
        <section>
            <div className="container-xxl my-5 py-5">
                <div className="section-header">
                    <div className="primary">{t('popular_courses_message')}</div>
                    <h2 className="title fw-medium mb-0">{t('popular_courses_title')}</h2>
                    <div>{t('popular_courses_desc')}</div>
                </div>

                <div className="section-content mt-5">

                    <div className="row">
                        {isLoading ?
                            Array.from({ length: 2 }, (_, index) => (
                                <div className="col-lg-3 col-md-4 mb-4 mt-2" key={index}>
                                    <div className="content-placeholder">
                                        <h5 className="w-100 min-h-200 placeholder-glow mt-2 me-3">
                                            <span className="placeholder col-12 min-h-200"></span>
                                        </h5>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder col-7"></span>
                                            <span className="placeholder col-12 mt-3"></span>
                                            <span className="placeholder col-7 mt-2"></span>
                                            <br />
                                            <span className="placeholder col-4 my-3"></span>

                                            <span className="placeholder col-12 mt-3"></span>
                                            <span className="placeholder col-10"></span>
                                            <span className="placeholder col-8"></span>
                                        </p>
                                    </div>
                                </div>
                            ))
                            :
                            <>
                                {courses && courses.length ?
                                    <>
                                        {courses.map((item, index) => (
                                            <div className="col-lg-3 col-md-4 mb-4 mt-2" key={index}>
                                                {/* <div className="col-lg-3 col-md-4 mb-4 mt-2" key={index} data-aos="fade-up" data-aos-easing="linear" data-aos-delay={index * 200}> */}
                                                <CourseCard course={item} appLang={webCtx.appLang} baseURL={baseURL} />
                                            </div>
                                        ))}
                                    </>
                                    : null}
                                <div className="text-end">
                                    <Link to={'/courses'} className="dim-text pointer">
                                        {t('view_all')}
                                    </Link>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
        </section>
    );
};

export default LandingCourses;