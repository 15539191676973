import React, { useContext, useEffect } from "react";

import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, Slide } from 'react-toastify';
import AOS from 'aos';
import axios from 'axios';

import WebContext from "./application/contexts/WebContext";
import Notifier from "./application/utils/Notifier";

import { ReactComponent as AssetLoader } from "./assets/images/loader.svg";

import 'aos/dist/aos.css';

import UserEnd from "./presentation/UserEnd";
const AdminPortal = React.lazy(() => import('./presentation/AdminPortal'));
const Login = React.lazy(() => import('./presentation/Auth/Login'));

function App() {
  const webCtx = useContext(WebContext);
  const navigate = useNavigate();

  useEffect(() => {
    initializeTheme();
    AOS.init({ once: true });

    axios.interceptors.response.use(
      function (successRes) {
        return successRes;
      },
      function (error) {
        if (!error.response.config.url.includes('login') && (error.response.status === 401 || error.response.statusText === 'Unauthorized')) {
          webCtx.changeLoginStatus(false);
          localStorage.removeItem('auth_token');
          navigate('/login');
          Notifier.notify('warning', "Session timeout, Try to login again");
        }
        return Promise.reject(error);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeTheme = () => {
    document.body.classList.add(webCtx.appTheme);
  };

  const isWaiting = () => {
    return (
      <div className="lazy-loader">
        <AssetLoader />
      </div>
    );
  };

  return (
    <>
      <React.Suspense fallback={isWaiting()}>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path="/*" element={<UserEnd />} />
          <Route path="/admin-portal/*" element={<RequireAuth><AdminPortal /></RequireAuth>} />
        </Routes>
        <ToastContainer transition={Slide} />
      </React.Suspense>
      {/* <ToastContainer transition={Slide} /> */}
    </>
  );
}

function RequireAuth({ children }) {
  const webCtx = useContext(WebContext);
  let location = useLocation();

  if (!webCtx.isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;

    // return children;
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
