import React, { useContext, useEffect } from 'react';

import { useTranslation } from "react-i18next";
import WebContext from '../../../application/contexts/WebContext';
import ClientApis from '../APIs/ClientApis';

import { ReactComponent as AssetEnglish } from './../../../assets/images/icons/flag-uk.svg';
import { ReactComponent as AssetBulgarian } from './../../../assets/images/icons/flag-bg.svg';
import assetLogo from './../../../assets/images/logo-nodis.png';

import './footer.scss';

const Footer = () => {
    const webCtx = useContext(WebContext);
    const { t, i18n } = useTranslation();

    // const [settingsData, setsettingsData] = useState({})

    const communityLinks = [
        { title: 'MyTextBook', link: 'https://mytextbook.net/' },
        { title: 'Imparo', link: 'https://imparo.ee/' },
        { title: 'Jobherland', link: 'https://jobherald.net/' },
    ];

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        ClientApis.feSettings()
            .then(res => {
                webCtx.setAppSettings(res.data.result);
                // setwebCtx.appSettings(res.data.result);
            })
            .catch(err => {
                console.log(err);
            })
    };

    const switchLanguage = (e) => {
        const lang = (webCtx.appLang === 'en') ? 'bg' : 'en';
        i18n.changeLanguage(lang);
        webCtx.switchLang(lang);
    };

    return (
        <footer className="footer">
            <hr className="separator m-0" />
            <div className="container">
                <div className="py-5">
                    <div className="row justify-content-between">
                        {/* <div className="col-md-12 text-center mb-4">
                            <h4 translate='no'>{t('brand_title')}</h4>
                        </div> */}
                        <div className="col-lg-7 mb-4 mb-lg-0">
                            <div className="row">

                                <div className="col-md-7 col-sm-6 mb-4 mb-md-0">
                                    <h5 className="mb-3 section-heading">{t('menu_contact_us')}</h5>

                                    <div className="f-link mb-4">
                                        <div className="section-heading">{t('address')}</div>
                                        <div>
                                            <a href={'mailto:' + webCtx.appSettings?.address} className="f-link-item">{webCtx.appSettings?.address}</a>
                                        </div>
                                    </div>

                                    <div className="f-link mb-4">
                                        <div className="section-heading">{t('mail')}</div>
                                        <div>
                                            <a href={'mailto:' + webCtx.appSettings?.contact_email} className="f-link-item">{webCtx.appSettings?.contact_email}</a>
                                        </div>
                                    </div>

                                    <div className="f-link mb-4">
                                        <div className="section-heading">{t('support')}</div>
                                        <div>
                                            <a href={'tel://' + webCtx.appSettings?.contact_number} className="f-link-item">{webCtx.appSettings?.contact_number}</a>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-5 col-sm-6 mb-4 mb-md-0">
                                    <h5 className="mb-3 section-heading">{t('community')}</h5>
                                    {communityLinks.map((item, index) => (
                                        <div className="f-link mb-3" key={index}>
                                            <a className="f-link-item" href={item.link} rel="noreferrer" target="_blank">{item.title}</a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="mb-5 logo-container">
                                <img src={assetLogo} alt="facebook" className="footer-brand-logo" />
                            </div>
                            <div className="language-selector d-flex align-items-center">
                                <div className="language-icon ms-2">
                                    {/* <img src={currentLang === 'en' ? asset_en_flag : asset_bg_flag} alt="icon" /> */}
                                    {webCtx.appLang === 'en' ? <AssetEnglish /> : <AssetBulgarian />}
                                </div>
                                <select className="language-seletor-input imparo-input-field" onChange={switchLanguage}>
                                    <option value={webCtx.appLang ? webCtx.appLang : ''} >{webCtx.appLang && webCtx.appLang === 'bg' ? 'Bulgarian' : 'English'}</option>
                                    {/* <option selected value="en">English</option> */}
                                    {webCtx.appLang === 'bg' && <option value="en">English</option>}
                                    {webCtx.appLang === 'en' && <option value="bg">Bulgarian</option>}
                                </select>
                            </div>
                            <hr className="separator" />

                            <div className="render-content" dangerouslySetInnerHTML={{ __html: webCtx.appSettings[`featured_text_${webCtx.appLang}`] }} />

                            {/* <div className="social-links">
                                <a href="#" className="facebook"><img src={asset_facebook_icon} alt="facebook" className={`${webCtx.appTheme === "dark-theme" ? 'light-icon' : ''}`} /></a>
                                <a href="#" className="twitter"><img src={asset_twitter_icon} alt="twitter" className={`${webCtx.appTheme === "dark-theme" ? 'light-icon' : ''}`} /></a>
                                <a href="#" className="linkedIn"><img src={asset_linkedIn_icon} alt="linkedIn" className={`${webCtx.appTheme === "dark-theme" ? 'light-icon' : ''}`} /></a>
                                <a href="#" className="github"><img src={asset_github_icon} alt="github" className={`${webCtx.appTheme === "dark-theme" ? 'light-icon' : ''}`} /></a>
                                <a href="#" className="instagram"><img src={asset_instagram_icon} alt="instagram" className={`${webCtx.appTheme === "dark-theme" ? 'light-icon' : ''}`} /></a>
                                <a href="#" className="mail"><img src={asset_mail_icon} alt="mail" className={`${webCtx.appTheme === "dark-theme" ? 'light-icon' : ''}`} /></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom py-2">
                <div className="container">
                    <div className="d-md-flex align-items-center justify-content-between">
                        <div className="copyright my-2 text">
                            {webCtx.appLang === "en" ? webCtx.appSettings?.copyright_text_en : webCtx.appSettings?.copyright_text_bg}
                        </div>
                        <div className="powered my-2">
                            {t('powered_by')} &nbsp;
                            <a href={webCtx.appSettings?.powered_by_link} target="_blank" rel="noreferrer">{webCtx.appSettings?.powered_by}</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    );
};

export default Footer;