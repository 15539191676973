import React from 'react';

import { Routes, Route } from 'react-router-dom';

import Navbar from './Navbar';
import Landing from './Landing';
import Footer from './Footer';
import ScrollToTop from '../../infrastructure/Common/ScrollToTop';

const Courses = React.lazy(() => import('./Courses'));
const ContactUs = React.lazy(() => import('./ContactUs'));
const CourseDetail = React.lazy(() => import('./Shared/CourseDetail'));
const BlogAndArticles = React.lazy(() => import('./BlogAndArticles'));
const Partners = React.lazy(() => import('./Partners'));


const UserEnd = () => {

    const isWaiting = () => {
        return (
            <div className="lazy-loader">
                loading...!
            </div>
        );
    };

    return (
        <React.Fragment>
            <Navbar />

            <div className="render-content">
                <React.Suspense fallback={isWaiting()}>
                    <ScrollToTop />
                    <Routes>
                        <Route path='/' element={<Landing />} />
                        <Route path='home' element={<Landing />} />
                        <Route path='courses' element={<Courses />} />
                        <Route path='courses/:courseId' element={<CourseDetail />} />


                        <Route path='contact-us' element={<ContactUs />} />

                        <Route path='partners/*' element={<Partners />} />
                        <Route path='blog-n-news/*' element={<BlogAndArticles />} />
                    </Routes>
                </React.Suspense>
            </div>

            <Footer />
        </React.Fragment>
    );
};

export default UserEnd;