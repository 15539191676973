import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import WebContext from '../../../../application/contexts/WebContext';
import Global from '../../../../infrastructure/Common/Global';
import ClientApis from '../../APIs/ClientApis';

const LandingPartners = () => {

    const { t } = useTranslation();
    const webCtx = useContext(WebContext);

    const [isLoading, setIsLoading] = useState(true);
    const [partners, setPartners] = useState([]);

    const baseURL = Global.baseUrl + "/";

    useEffect(() => {
        getPartners();
    }, []);

    const getPartners = () => {
        ClientApis.partners('home=1')
            .then(res => {
                // setTimeout(() => {
                setPartners(res.data?.result?.posts);
                setIsLoading(false);
                // }, 2000);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    };

    return (
        <section>
            <div className="container-xxl my-5 py-5">

                <div className="section-content">
                    <div className="row align-items-center">
                        <div className="col-md-6 my-4">
                            <div className="section-header" data-aos="fade-right">
                                <div className="primary">{t('partners_message')}</div>
                                <h2 className="title fw-medium mb-0">{t('partners_title')}</h2>
                                <div>{t('partners_desc')}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {isLoading ?
                                Array.from({ length: 3 }, (_, index) => (
                                    <div className="content-placeholder d-flex px-3" key={index}>
                                        <h5 className="sq-60 placeholder-glow mt-2 me-3">
                                            <span className="placeholder col-12 sq-60"></span>
                                        </h5>
                                        <div className="content-placeholder mt-1 w-100">
                                            <h6 className="placeholder-glow mb-2">
                                                <span className="placeholder col-5"></span>
                                            </h6>
                                            <div className="card-text placeholder-glow">
                                                <span className="placeholder col-12 min-h-12 mt-12"></span>
                                                <span className="placeholder col-9 min-h-12 mt-12"></span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                <>
                                    {partners && partners.length ?
                                        <>
                                            {partners.map((item, index) => (
                                                // <div className="home-partner p-3 mb-2" key={index} data-aos="fade-up" data-aos-duration={(index * 500)} data-aos-easing="linear">
                                                <div className="home-partner p-3 mb-2" key={index}>
                                                    <div className="d-flex align-items-end me-3">
                                                        <div className="poster-container" style={{ backgroundImage: `url('${baseURL + item.featured_url}')` }}></div>
                                                    </div>

                                                    <div className="content">
                                                        <Link to={`/partners/${item.id}`} className="title fw-medium">{item['title_' + webCtx.appLang]}</Link>
                                                        <div className="desc">
                                                            {/* {item['content_' + webCtx.appLang]} */}
                                                            <div className="render-content" dangerouslySetInnerHTML={{ __html: item[`content_${webCtx.appLang}`] }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="text-end">
                                                <Link to={'/partners'} className="dim-text pointer">
                                                    {t('view_all')}
                                                </Link>
                                            </div>
                                        </>
                                        : null}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingPartners;