import React from 'react';
import { Link } from 'react-router-dom';

import * as moment from 'moment';

import { ReactComponent as AssetCalendar } from './../../../../assets/images/icons/calendar.svg';

import './course-card.scss';

const CourseCard = ({ course, appLang, baseURL }) => {
    const languages = [
        { key: "en", value: 'English' },
        { key: "bg", value: 'Bulgarian' }
    ];

    return (
        <div className="course-card">
            <Link to={`/courses/${course.id}`} >
                <div className="p-0">
                    <div className="img-container">
                        <div className="course-img" style={{ backgroundImage: `url('${baseURL + course.featured_img}')` }}></div>
                    </div>
                    <div className="p-3 pt-2">
                        <div className="hl-info mb-2">
                            <div className="small d-flex align-items-start"><AssetCalendar className="me-2" /> {course.created_at ? moment(course.created_at).format('DD-MMM-YYYY') : ''}</div>
                        </div>
                        <h5 className="fw-normal mb-1 title" title={course['title_' + appLang]}>{course['title_' + appLang]}</h5>
                        <div className="d-flex flex-wrap my-1">
                            {course.languages?.map((lang, i) => (
                                <div className="nodis-tag bg-max me-2 mb-2 py-1 text-nowrap" key={'lang-' + i}>
                                    {languages.find(langObj => langObj.key === lang)?.value}
                                </div>
                            ))}
                            {/* {course['category_name_' + appLang]} */}
                        </div>
                        <p className="card-text" title={course['short_desc_' + appLang]}>
                            <div
                                className="render-content"
                                dangerouslySetInnerHTML={{ __html: course['short_desc_' + appLang].length > 120 ? course['short_desc_' + appLang].substring(0, 119) + '...' : course['short_desc_' + appLang] }}
                            />
                        </p>
                    </div>
                </div>
            </Link>
            {/* <div className="course-footer d-flex justify-content-between p-3">
                <div className="price">{course.currency}{course.fee}</div>
                <div className="status text-capitalize text-success">{course.status}</div>
            </div> */}
        </div>
    );
};

export default CourseCard;